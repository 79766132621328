'use client'
import React, { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import store from '@redux/store'
import { library } from "@fortawesome/fontawesome-svg-core"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { FacebookProvider } from 'react-facebook'
import { usePathname } from 'next/navigation'
import packageInfo from '../../package.json'

library.add(far, fas)
let _loaded = false

export default function RootProviders({ children }: Readonly<{ children: React.ReactNode }>) {
  const path = usePathname()
  useEffect(() => {
    const anchorSelector = 'a[href^="#"]'
    const anchorList = document.querySelectorAll(anchorSelector)
    
    anchorList.forEach((link) => {
      const anchorLink = link as HTMLAnchorElement
      anchorLink.addEventListener('click', (e) => {
        e.preventDefault()
        const destination = document.querySelector(anchorLink.hash)
        if (destination) {
          destination.scrollIntoView({
            behavior: 'smooth'
          })
        }
      })
    })
  }, [path])
  
  useEffect(() => {
    if (_loaded) return
    _loaded = true
    console.info(`📻 v${packageInfo.version}`)
  }, [])
  return (
    <FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID!} language='he_IL'>
      <ReduxProvider store={store}>
        {children}
      </ReduxProvider>
    </FacebookProvider>
  )
}