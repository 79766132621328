"use client"
import { useEffect, useRef } from 'react'
import Hls from 'hls.js'
import { useAppDispatch } from '@redux/hook'
import { setLoading, setPaused, setPlaying } from '@redux/slices/playerSlice'
import { usePlayer } from '@hooks'

export default function Player() {
  const dispatch = useAppDispatch()
  const player = usePlayer()
  const audioRef = useRef<HTMLAudioElement | null>(null)
  useEffect(() => {
    if (!audioRef.current) {
      dispatch(setPaused())
      return
    }
    const audio = audioRef.current
    let hls: Hls | null = null

    if (!player.url) {
      dispatch(setPaused())
      audio.pause()
    } else {
      dispatch(setLoading())
      if (Hls.isSupported() && player.url.endsWith('.m3u8')) {
        hls = new Hls()
        hls.loadSource(player.url)
        hls.attachMedia(audio)
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          audio.play()
        })
        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error('HLS error:', data)
        })
      } else if (audio.canPlayType('application/vnd.apple.mpegplayer.url') && player.url.endsWith('.m3u8')) {
        // Fallback for native HLS support (e.g., Safari)
        audio.src = player.url
        audio.play()
      } else {
        // Regular MP3 or other audio files
        audio.src = player.url
        audio.currentTime = 0
        audio.load()
        audio.play()
      }

      audio.onplaying = () => dispatch(setPlaying())
      audio.onpause = () => dispatch(setPaused())
    }

    return () => {
      if (hls) {
        hls.destroy()
        hls = null
      }
      audio.onplaying = null
      audio.onpause = null
    }
  }, [player.url])

  if (!player.url) return null

  return <audio ref={audioRef} />
}
