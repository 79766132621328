"use client"
import { IStation } from '@models'
import Link from 'next/link'
import React, { useMemo } from 'react'
import styles from './stations.module.scss'
import { useStorage } from '@hooks'
import Icon from '@components/icon'
import Logo from '@components/station/logo'

interface IStations {
    stations: IStation[]
    direction?: 'row' | 'column'
    size?: 'lg' | 'md' | 'sm'
    title?: string
    align?: 'center' | 'flex-start'
    lazy?: boolean
}
export default function Stations({stations, title, align, direction = 'row', size = 'lg', lazy = false}: IStations) {
    const { get } = useStorage("favorites")
    const favorites = useMemo(() => size === 'sm'? []: (get() || []) as string[], [])

    if (stations.length === 0)
        return null

    return (
        <div className={styles.container} style={{alignItems: align}}>
            {
                title && <h3>{title}</h3>
            }
            <nav className={`${styles[direction]} ${styles[size]}`} style={{justifyContent: align}}>
            {
                stations.map(station => (
                    <Link href={`/station/${station.id}`} key={station.id}>
                        <div className={styles.logo}>
                            <Logo station={station} lazy={lazy} />
                            {
                                favorites.includes(station.id) && (
                                    <Icon icon={['fas', 'heart']} />
                                )
                            }
                        </div>
                        {
                            size !== 'sm' && <span>{station.name}</span>
                        }
                    </Link>
                ))
            }
            </nav>
        </div>
    )
}