"use client"

export default function useStorage(key: string) {
    const get = <T>(): T[] => {
        if (typeof window === 'undefined') return []
        const storage = localStorage.getItem(key)
        if (!storage)
            return []
        return JSON.parse(storage)
    }

    const remove = (stationId: string) => {
        let previous = (get() || []) as string[]
        previous = previous.filter(id => id !== stationId)
        localStorage.setItem(key, JSON.stringify(previous))
    }
    const add = (stationId: string) => {
        let previous = (get() || []) as string[]
        if (previous.includes(stationId))
            previous = previous.filter(id => id !== stationId)
        previous.unshift(stationId)
        if (previous.length >= 10)
            previous = previous.slice(0, 10)
        localStorage.setItem(key, JSON.stringify(previous))
    }

    const set = (value: any) => {
        localStorage.setItem(key, JSON.stringify(value))
    }
    
    return { add, get, set, remove }
}