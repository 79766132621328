import { RootState } from '@redux/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlayerStatus } from '@types'

const initialState: {
  status: PlayerStatus
  url: string
  action: 'play' | 'pause'
} = {
  status: 'paused',
  url: '',
  action: 'pause'
}

const playerSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    setPlaying: (state) => {      
      state.status = 'playing'
    },
    setPaused: (state) => {
      state.status = 'paused'
    },
    setLoading: (state) => {
      state.status = 'loading'
    },
    play: (state, action: PayloadAction<string>) => {
      state.url = action.payload
    },
    pause: (state) => {
      state.url = ''
    }
  }
})

export const { setPlaying, play, pause, setLoading, setPaused } = playerSlice.actions
export const selectPlayerStatus = (state: RootState) => state.player.status
export const selectPlayer = (state: RootState) => state.player

export default playerSlice.reducer
