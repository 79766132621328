"use client"
import React, { useEffect } from 'react'
import styles from './banner.module.scss'
import { usePathname } from 'next/navigation'
import { __DEV__ } from '@lib/helpers'

const SLOTS: { [key: string]: string } = {
    "1358349327": "0",
    "9619982722": "300_600",
}
const initAd = () => {
    try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
        console.log(err)
    }
}
export default function Banner({ slot }: { slot: string }) {
    const path = usePathname()
    
    useEffect(() => {
        initAd()
    }, [path])

    if (path.startsWith('/admin'))
        return null
    
    return (
        <div className={`${styles.container} ${styles[`size${SLOTS[slot]}`]} ${__DEV__ && styles.dev}`}>
            <div key={path.replace(/\//g, "-") + "-" + slot + "-" + "default-ad-unit-type"}>
            <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client={process.env.NEXT_PUBLIC_ADSENSE_PUBLISHER_ID}
                data-ad-slot={slot}
                data-ad-format="auto"
                data-full-width-responsive="true" />
            </div>
        </div>
    )
}