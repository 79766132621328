import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"hebrew\",\"latin\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/acum.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/app/android.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/app/ios.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/app/preview.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logos/app.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logos/logo_mobile.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logos/radiohead.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/app/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/banner/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header/header.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/download/download.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/footer/footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/player/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/search/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/stations/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.scss");
