"use client"
import styles from './app.module.scss'
import Image from 'next/image'
import { IDevices } from '@models'
import logo from '@logos/app.webp'

export default function App({os}: {os: IDevices}) {
  const appStoreUrl = os === 'ios'? process.env.NEXT_PUBLIC_IOS_URL!: process.env.NEXT_PUBLIC_ANDROID_URL!

  const openStore = () => {
    window.open(appStoreUrl, '_blank')
  }
  return (
    <div className={styles.container} onClick={openStore}>
      <Image src={logo} alt='רדיו ישראלי אונליין' />
      <div className={styles.title}>
        <h3>רדיו ישראלי אונליין</h3>
        <h4>Radio Head</h4>
        <h5>אפליקציה - חינם</h5>
      </div>
      <div className={styles.open}>
        הורדה<br />עכשיו
      </div>
    </div>
  )
}
