"use client"
import styles from './search.module.scss'
import Icon from "@components/icon"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { useRouter } from 'next/navigation'
import { KeyboardEvent} from 'react'

export default function Search({ size = 'sm' }: { size?: 'sm' | 'md' | 'lg' }) {
  const router = useRouter()

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key != 'Enter') return
    const query = e.currentTarget.value
    if (!query) return
    router.push(`/search?q=${query}`)
  }

  return (
    <div className={`${styles.container} ${styles[size]}`}>
      <input type="text" placeholder="חיפוש תחנות רדיו" onKeyDown={handleKeyDown} />
      <Icon icon={faSearch} />
    </div>
  )
}